<script lang="ts" setup>
import { toast } from "vue3-toastify";

const props = defineProps<{
	url: string;
	toastId: string;
}>();

const { t } = useT();
const { copy } = useClipboard();

const handleCopyClick = () => {
	copy(props.url);
	toast.success(t("Link copied"), {
		containerId: props.toastId,
		icon: h("i", { class: "toast-icon icon-checked" }),
		theme: toast.THEME.DARK,
		position: toast.POSITION.BOTTOM_RIGHT,
		transition: toast.TRANSITIONS.SLIDE,
		autoClose: 5000
	});
};
</script>
<template>
	<div class="m-url box">
		<AText type="small">{{ t("Copy and send this link to your friend") }}</AText>
		<div class="content">
			<AText class="url" type="small" data-tid="ref-url"> {{ props.url }}</AText>
			<AButton size="xs" variant="primary" class="button" data-tid="copy-ref-url-btn" @click="handleCopyClick">
				{{ t("Copy") }}
			</AButton>
		</div>
	</div>
</template>

<style lang="scss">
#referralModal {
	&.Toastify {
		&__toast-container {
			z-index: 100000;
			width: 345px;

			@include media-breakpoint-down(lg) {
				width: calc(100% - 32px);
				bottom: 8px;
				left: auto;
			}
		}
	}
	.icon-checked {
		background-image: url(/assets/icons/20/success.svg);
	}
}

.Toastify {
	&__toast {
		@include media-breakpoint-down(lg) {
			margin-bottom: 8px;
		}
	}
}
</style>

<style lang="scss" scoped>
.box {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.content {
	display: flex;
	padding: 1px;
	align-items: center;
	justify-content: space-between;
	border: 1px solid var(--neutral);
	border-radius: 5px;
}

.url {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: calc(100% - 98px);
	padding: 10px;
	color: var(--gray-200);
}

.button {
	width: 98px;
}
</style>
